
const BASEURL = `https://darfarha-crm.cyparta.com/${localStorage.getItem("lang")?localStorage.getItem("lang").replace(/\"/g,""):"en"}`;
// "https://dolphin-app-u2qj5.ondigitalocean.app"
// "http://192.168.1.45:8000"
const REGISTER = "api/register/";
const NOTIFICATIONS = "aqar/notification/";
const POSTS = "aqar/api/router/Post/";
const CLIENTS = "aqar/api/router/Client/";
const CLIENTSFEKHEDMETAK = "fe-khedmetak/clients/";
const USERFEKHEDMETAK = "fe-khedmetak/users/";
const USERFEKHEDMETAKHISTORY = "fe-khedmetak/user/history/";

const CLIENTS_FEkhedmetak = "fe-khedmetak/clients/";
const CLIENTS_FEkhedmetak_history = "fe-khedmetak/clients/history/";
const USERSALERT="/fe-khedmetak/alert/";
const USERS_FEkhedmetak = "fe-khedmetak/users/";
const UNITS_FEKHEDMETAK = "fe-khedmetak/property/";
const GETUSERACTIVE = "fe-khedmetak/active_users";
const OVERVIEW="/fe-khedmetak/overview/";
const MEANSOFCOMFORT="aqar/amenities/"
const REQUEST = "fe-khedmetak/requests/";
const REQUESTEMPLOYEE="aqar/api/router/Employee/";
const DELETED_CLIENTS = "aqar/api/router/RestoreClient/";
const CLIENTS_HISTORY = "aqar/api/router/HistoryClient/";
const CLIENTS_COMMENTS = "aqar/api/router/CommentClient/";
const CLIENTS_TRANSFER = "aqar/api/transfer/";
const CLIENTS_TRANSFER_PROJECT = "aqar/api/transferclientproject/";
const CLIENTS_SELECTED_DELETE = "aqar/api/ClientGroupDelete/";
const USER_CAN_LOGIN="/fe-khedmetak/users/activity";
const STATUS = "aqar/api/router/Status/";
const EMPLOYEES = "aqar/api/router/Employee/";
const EMPLOYEES_PERMISSIONS = "aqar/api/router/EmployeePermission/";
const BLOCK_EMPLOYEE = "aqar/api/BlockEmployee/";
const PROJECTS = "aqar/api/router/Project/";
const CHANNELS = "aqar/api/router/Channel/";
const JOBS = "aqar/api/router/Job/";
const JOBTREE="/aqar/api/jobs/tree/";
const EMPLOYEETREE="/aqar/api/employees/tree/";

const FOLLOWERTREE="/fe-khedmetak/followers/tree/";
const TEAMS = "aqar/api/router/Team/";
const UNITS = "aqar/api/router/Unit/";
const PROJECTEDIT="aqar/api/router/Project/"
const UNITSFEKHEDMETAK = "fe-khedmetak/property/";
// const USERFEDELETE="fe-khedmetak/users/"
const JOB_PERMISSIONS = "aqar/api/router/JobPermission/";
const PERMISSIONS = "api/getpermissions/";
const CHANGE_PASSWORD = "api/ChangePassword/";
const USER_INFO = "aqar/api/router/UserInfo/";
const FCMDRIVERS="fcm/devices/"
const COMPANY = "aqar/api/router/Company/";
const FILES_HISTORY = "aqar/api/router/HistoryFile/";
const DELETECONTRACT="aqar/api/router/Contract/"
const SUMMARY = "aqar/api/summary/";
const EXPORT_CLIENTS = "aqar/api/import/";
const CONTRACT="aqar/api/router/Contract/"
const IMPORT_CLIENTS = "aqar/api/export/";
const LOGIN = "aqar/login/";
const COUNTRYCODE="https://restcountries.com/v3.1/all"
const COUNTRY_FILTER = "api/countries/";
const STATES = "api/states/";
const STATE_CITIES =
  "https://countriesnow.space/api/v0.1/countries/state/cities";

export default BASEURL;

export {
  LOGIN,
  NOTIFICATIONS,
  REGISTER,
  POSTS,
  CLIENTS,
  DELETED_CLIENTS,
  CLIENTS_HISTORY,
  CLIENTS_COMMENTS,
  CLIENTS_TRANSFER,
  CLIENTS_TRANSFER_PROJECT,
  STATUS,
  EMPLOYEES,
  EMPLOYEES_PERMISSIONS,
  BLOCK_EMPLOYEE,
  PROJECTS,
  CHANNELS,
  JOBS,
  TEAMS,
  UNITS,
  JOB_PERMISSIONS,
  PERMISSIONS,
  CHANGE_PASSWORD,
  USER_INFO,
  COUNTRYCODE,
  COMPANY,
  FILES_HISTORY,
  USER_CAN_LOGIN,
  SUMMARY,
  EXPORT_CLIENTS,
  IMPORT_CLIENTS,
  CLIENTS_SELECTED_DELETE,
  EMPLOYEETREE,
  COUNTRY_FILTER,
  STATES,
  STATE_CITIES,
  CLIENTS_FEkhedmetak,
  USERS_FEkhedmetak,
  UNITS_FEKHEDMETAK,
  CLIENTSFEKHEDMETAK,
  UNITSFEKHEDMETAK,
  USERFEKHEDMETAK,
  CLIENTS_FEkhedmetak_history,
  REQUEST,
  GETUSERACTIVE,FCMDRIVERS,MEANSOFCOMFORT,CONTRACT,DELETECONTRACT,PROJECTEDIT,USERFEKHEDMETAKHISTORY,REQUESTEMPLOYEE,USERSALERT,OVERVIEW,JOBTREE,FOLLOWERTREE
};
